<template>
  <div class="home-container">
    <div class='sc-top'>
      <div class='top-center'>
        <van-tabs swipe-threshold='3' @click='selects' :active="active" shrink>
          <van-tab title="商超代金券">
            <div class="active_two">
              <van-tabs @click='selectstatus' class='navtab' title-active-color='black' v-model="actives" animated>
                <van-tab v-for="(item,ind) in navlist" :key="ind" :title="item.name">
                  <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadings">
                    <div v-for="(i,ind) in list" :key="ind" :class="[status == 0 ? 'qy-fen' : 'qy-hui']" class="qy-content">
                      <div class="qy-content-img">
                        <img :src="i.logo">
                      </div>
                      <div :class="[status != 0 ? 'lineheights' : '']" class="qy-content-info ">
                        <span>{{ i.storeName }}</span>
                        <!-- <span :style="{color:(status==0?'#CB4643':'#999999')}" >
                                    </span> -->
                        <span>
                          满{{ i.transactionMinimum }}可用
                        </span>

                        <span v-if="(i.status === 1)" :style="{ color: (status == 0 ? '#CB4643' : '#999999') }">
                          {{ i.effectiveTimeEnd }}
                        </span>
                        <span v-if="(i.status === 2)">
                          使用时间:{{ i.useTime }}
                        </span>

                        <span v-if="i.status === 0" :style="{ color: (status == 0 ? '#CB4643' : '#999999') }">
                          {{ i.effectiveTimeEnd }}
                        </span>

                      </div>
                      <div style='position: absolute;
                  right: 0.3rem;
                  top: 0.3rem;' v-show="status !== 2">
                        <img v-show="i.status == 0" style="width: 2rem;" src="../../assets/img2/wjh.png">
                        <img v-show="i.status == 1" style="width: 2rem;" src="../../assets/img2/yjh.png">
                        <img v-show="i.status == 2" style="width: 2rem;" src="../../assets/img2/ysy.png">
                      </div>

                    </div>

                  </van-list>

                </van-tab>
              </van-tabs>
            </div>

          </van-tab>
        </van-tabs>

      </div>

    </div>

  </div>
</template>

<script>
import {
  getReceiveCoupon,
  getRightsById,
  recieveRights,
  getCouponInfo,
  getUserCouponList,
  getUserCnpcRightsList,
  getUserInernetRightsList
} from '@/api/user'
import {
  Dialog
} from 'vant';
import {
  mapState
} from 'vuex'
import {
  getItem,
  setItem
} from '@/utils/storage'

export default {
  name: 'home',

  props: {},
  data() {
    return {
      navlist: [{
        name: '未使用'
      },
      {
        name: '已使用'
      },
      {
        name: '已失效'
      }

      ],
      enterpriseType: '0',
      active: 0, //被激活的列表
      actives: 0, //被激活的列表
      activess: 0,
      channels: [], //频道列表
      isChannelEditShow: false, //频道编辑层
      tablist: [{
        name: '商超代金券'
      },
        // {
        //   name: '互联网会员权益券'
        // },


      ],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      from: 1,
      status: 0
    }
  },

  watch: {},
  created() {
    // this.loadChannels()
  },
  mounted() {
    if (this.$store.state.user.token) {
      this.islogin = true

    }
    this.detailid = this.$route.params.detailid
    console.log(this.$route.params.detailid)
    if (this.$route.params.detailid === undefined) {
      this.detailid = getItem("detailid1")
    }
    setItem('detailid1', this.detailid)
    this.data = this.detailid
    this.onLoad()
    this.record({
      "type": 9,
      "resourceId": ''
    })
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        this.refreshing = false;
      }, 1000);
      this.form = 0
      this.loadings()
    },
    selectstatus(name, title) {
      this.status = name
      this.loading = false,
        this.finished = false,
        this.list = []
      this.from = 1
      this.onLoad()

    },
    selects(val) {
      console.log('进入', val)
      this.list = []
      if (val == 0) {
        this.status = 0
        this.actives = 0
        this.from = 1
        this.enterpriseType = '0'
        this.onLoad()
      }
      else if (val == 1) {
        this.from = 0
        this.loadingsRights()

      } else if (val == 2) {

        this.from = 0
        this.loadingsZgSy()
      } else {
        this.enterpriseType = '2'
        this.status = 0
        this.activess = 0
        this.from = 0
        this.onLoad()
      }

    },


    async loadings() {
      this.loading = true
      this.from = this.from + 1

      await this.onLoad()
      this.loading = false
    },
    async loadingsRights() {
      this.loading = true
      this.from = this.from + 1

      if (!this.list) {
        this.list = []
      }

      let parma = {
        // userId:12,
        //   "userMobile":"15028155600",
        "from": this.from,
        "size": 10,

      }
      const {
        data
      } = await getUserInernetRightsList(parma)
      if (JSON.stringify(data.data) === '[]') {
        this.loading = true
        this.finished = true
      }
      if (JSON.stringify(this.list) === '[]') {
        this.list = data.data
      } else {
        data.data.forEach((i, k) => {
          this.list.push(i)

        })
      }



      this.loading = false


    },
    async loadingsZgSy() {

      this.loading = true
      this.from = this.from + 1

      if (!this.list) {
        this.list = []
      }

      let parma = {
        // userId:12,
        //   "userMobile":"15028155600",
        "from": this.from,
        "size": 10,

      }
      const {
        data
      } = await getUserCnpcRightsList(parma)
      if (JSON.stringify(data.data) === '[]') {
        this.loading = true
        this.finished = true
      }
      if (JSON.stringify(this.list) === '[]') {
        this.list = data.data
      } else {
        data.data.forEach((i, k) => {
          this.list.push(i)

        })
      }



      this.loading = false

    },

    async onLoad() {
      if (this.list == null) {
        this.list = []
      }
      let parma = {
        // userId:12,
        //   "userMobile":"15028155600",
        "from": this.from,
        "size": 10,
        // enterpriseType: '0',
        // "status": 2,
        enterpriseType: this.enterpriseType,
        "status": this.status

      }
      const {
        data
      } = await getUserCouponList(parma)
      if (JSON.stringify(data.data) === '[]') {
        this.loading = true
        this.finished = true

      }

      if (JSON.stringify(this.list) === '[]') {
        this.list = data.data
      } else {
        data.data.forEach((i, k) => {
          this.list.push(i)

        })
      }
    },


  }
}
</script>

<style lang="less">
.van-tab--active {
  font-weight: 700;
}

html {
  background: #f4f4f4;
}

img {
  pointer-events: none;
}

.sc-button {
  background-image: url(../../assets/img/scbutton.png);
  width: 90%;
  margin: 0 auto;
  position: fixed;
  bottom: 0.2rem;
  background-size: 100% 100%;
  left: 0;
  right: 0;
  color: #ffffff;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  font-size: 0.38rem;
  padding: 0.4rem;
}

p {
  margin-bottom: 0.2rem;
}

.sc-text {
  font-size: 0.32rem;
  background: #fff;
  width: 93%;
  margin: 0 auto;
  margin-top: 3rem;
  box-sizing: border-box;
  padding: 0.2rem 0.5rem;
  line-height: 0.5rem;
  border-radius: 0.2rem;
  color: #333333;
}

.sc-step {
  z-index: 999;
  /* position: absolute; */
  margin: 0 auto;
  display: flex;
  width: 73%;
  justify-content: space-between;

  img {
    display: block;
    /* float: left; */
    width: 1.2rem;
    margin: 0.2rem 0;
  }
}

.sc-step-loop {
  position: absolute;
  height: 0.1rem;
  background: #ffe0e5;
  border-radius: 0.5rem;
  width: 49%;
  /* margin: 0px auto; */
  /* bottom: 1.05rem; */
  top: 0.7rem;
  left: 2.4rem;

  span {
    display: block;
    width: 100%;
    height: 100%;
    background: #f12f51;
  }
}

.home-container {
  .qy-content {
    position: relative;
    width: 100%;
    height: 2.8rem;

    background-size: 100% 100%;
    margin: 0.2rem 0;
    display: flex;
  }

  .qy-fen {
    background-image: url(../../assets/img/quanyifen.png);
  }

  .qy-hui {
    background-image: url(../../assets/img/qianyihui.png);
  }

  .qy-content-img {
    border: 1px solid #f7f4f4;

    img {
      width: 1.8rem;
      height: 2.2rem;
      display: block;
      padding: 0.3rem 0.35rem;
    }
  }

  .qy-content-info {
    padding-top: 0.2rem;

    span {
      display: block;

      line-height: 0.6rem;
      margin-left: 0.5rem;

      &:nth-child(1) {
        font-size: 0.34rem;
        font-weight: 700;
      }

      &:nth-child(2) {
        font-size: 0.3rem;
        background: #ffe9e9;
        border-radius: 0.48rem;
        text-align: center;
        /* margin: 0.28rem; */
        width: 2.5rem;
        color: #e60027;
      }

      &:nth-child(3) {
        font-size: 0.32rem;
        color: #cb4643;
      }
    }
  }

  .qy-button {
    width: 1rem;
    height: 100%;
    background-size: 100% 100%;
    position: absolute;
    right: 0.05rem;

    span {
      font-size: 0.38rem;
      display: block;
      width: 25%;
      color: #ffffff;
      text-align: center;
      margin: 0.62rem auto;
    }
  }

  .qy-button-fen {
    background-image: url(../../assets/img/quanyijihuo.png);
  }

  .qy-button-hui {
    background-image: url(../../assets/img/quanyiweijihuo.png);

    span {
      color: #999999 !important;
    }
  }

  .sc-top {
    // background-image: url(../../assets/img/sctop.png);
    width: 100%;
    height: 2.7rem;
    background-size: 100% 100%;

    .top-center {
      // padding: 0.5rem;
      box-sizing: border-box;
      margin: 0 auto;
      background: #f4f4f4;

      .active_two {
        .van-tabs__nav--line {
          background: #f4f4f4;
        }

        .van-tabs__line {
          background-color: #333333;
        }
      }

      img {
        display: block;
        width: 1.8rem;
        height: 2rem;
      }

      .list-info {
        padding-left: 0.5rem;

        span {
          display: block;
          font-size: 0.3rem;

          &:nth-child(1) {
            font-size: 0.4rem;
            color: #333333;
            font-family: SourceHanSansCN-Regular;
          }

          &:nth-child(2) {
            padding: 0.1rem 0;
            font-size: 0.3rem;
            background: #ffe9e9;
            border-radius: 0.48rem;
            text-align: center;
            margin: 0.28rem;
            width: 2.5rem;
            color: #e60027;
            margin-left: 0;
          }

          &:nth-child(3) {
            font-size: 0.3rem;
            color: #999999;
          }
        }
      }
    }
  }

  .rights-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.2rem 0.5rem;
    border: 1px solid #f3f3f3;
    background: url(../../assets/img2/goodslistbg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0.3rem 0;
  }

  .rights-text {
    margin: 0 0.5rem;
    font-size: 0.35rem;

    span {
      display: block;
      font-size: 0.35rem;

      // width: 80%;
      &:nth-child(1) {
        font-weight: 700;
        font-size: 0.4rem;
        color: #333333;
        font-family: SourceHanSansCN-Regular;
        // overflow: hidden; //超出的文本隐藏
        // text-overflow: ellipsis; //溢出用省略号显示
        // display: -webkit-box;
        // -webkit-line-clamp: 1; // 超出多少行
        // -webkit-box-orient: vertical;
      }

      &:nth-child(2) {
        margin: 0.3rem 0;
      }
    }
  }
}
</style>
